@import "tailwindcss";

@theme {
  --color-custom-pink: #ffc0cb;
  --font-family-helvetica: "Helvetica Neue Medium", Helvetica, Arial, sans-serif;
  --font-family-mono: "IBM Plex Mono", monospace;

  --spacing-safe-top: env(safe-area-inset-top);
  --spacing-safe-bottom: env(safe-area-inset-bottom);
  --spacing-safe-left: env(safe-area-inset-left);
  --spacing-safe-right: env(safe-area-inset-right);
}
